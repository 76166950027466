@import "../../../config/style-guide/colours.scss";

.settings {
  zoom: 0.75;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 30%;
  min-width: 300px;
  min-height: 300px;
  text-align: center;
  vertical-align: middle;
  color: black;
  background: #4a4a4a;
  padding: 1em;
  font-weight: lighter;
  z-index: 2;
}

.header {
  margin-top: 0;
  margin-bottom: 1em;
  color: #cfcfcf;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  cursor: pointer;
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;
  margin: 0 auto;
}

.settingElement {
  text-align: left;
  align-self: auto;
  margin-bottom: 0.5em;
}

.label {
  display: inline-block;
  min-width: 200px;
  width: 200px;
}

.rollbackValue {
  height: 2em;
  width: 48px;
  box-sizing: border-box;
  border: none;
  text-align: center;
  font-weight: bold;
  margin-right: 16px;
  vertical-align: middle;
}

.timecodeLabel {
  display: block;
  text-align: center;
}

.playerButton {
  width: 48px;
  height: 48px;
  padding: 0.5em;
  border: 0;
  // color: white;
  color: #cfcfcf;
  background: $color-darkest-grey;
  font-size: 1em;
  cursor: pointer;
  margin-right: 0.3rem;
  margin-top: 0.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playerButton {
  width: 100%;
  height: 48px;
  margin: 0;
}

.playerButton:hover {
  background-color: gray;
}

.speakerDropdown {
  padding-left: 0;
  border: 1px solid #ddd;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  width: 200px;
  margin: 0;
  padding: 2px 0;
  li {
    display: flex;
    justify-content: space-between;
    list-style: none;
    span {
      padding: 5px 10px;
    }
    .speakerLabel {
      width: 100%;
    }
    &:hover {
      cursor: pointer;
      background: #044d55;
      color: #fff;
    }
  }
}
